import * as React from 'react';

interface InputProps extends React.ComponentProps<'input'> {
  icon?: React.ReactNode | null;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(({ type, icon, className, ...props }, ref) => {
  return (
    <div className="relative flex items-center min-w-56">
      {icon && <div className="absolute flex items-center pointer-events-none left-3 text-text-primary">{icon}</div>}
      <input
        type={type}
        className={`
          flex w-full h-10 px-3 py-2 text-sm border border-stroke-default rounded-md
          bg-bg-contrastDefault file:border-0 file:bg-transparent 
          file:text-sm file:font-medium file:text-foreground placeholder:text-text-secondary 
          focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-stroke-active
          disabled:cursor-not-allowed disabled:opacity-50 
          md:text-sm 
          ${icon ? 'pl-9' : ''}
          ${className || ''}
        `}
        ref={ref}
        {...props}
      />
    </div>
  );
});

Input.displayName = 'Input';

export { Input };
