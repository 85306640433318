import { Slot } from '@radix-ui/react-slot';
import { cva, type VariantProps } from 'class-variance-authority';

import { cn } from '@/utils/Types';
import { forwardRef } from 'react';

const buttonVariants = cva(
  'inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50',
  {
    variants: {
      variant: {
        default:
          'bg-[var(--color-button-primary-fill-default)] hover:bg-[var(--color-button-primary-fill-hover)] text-[var(--color-button-primary-text-default)] disabled:bg-[var(--color-button-primary-fill-disabled)] disabled:text-[var(--color-button-primary-text-disabled)]',
        destructive: 'bg-critical text-white hover:bg-critical/90',
        outline:
          'border border-[var(--color-stroke-default)] bg-[var(--color-button-outline-fill-default)] hover:bg-[var(--color-button-outline-fill-hover)] text-[var(--color-button-outline-text-default)] disabled:bg-[var(--color-button-outline-fill-disabled)] disabled:text-[var(--color-button-outline-text-disabled)]',
        secondary:
          'bg-[var(--color-button-secondary-fill-default)] hover:bg-[var(--color-button-secondary-fill-hover)] text-[var(--color-button-secondary-text-default)] disabled:bg-[var(--color-button-secondary-fill-disabled)] disabled:text-[var(--color-button-secondary-text-disabled)]',
        ghost: 'hover:bg-accent hover:text-accent-foreground',
        link: 'text-primary underline-offset-4 hover:underline',
      },
      size: {
        default: 'h-10 px-4 py-2',
        sm: 'h-9 rounded-md px-3',
        lg: 'h-11 rounded-md px-8',
        icon: 'h-10 w-10',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  },
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : 'button';
    return <Comp className={cn(buttonVariants({ variant, size, className }))} ref={ref} {...props} />;
  },
);
Button.displayName = 'Button';

export { Button, buttonVariants };
