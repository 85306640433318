const RAC_LINK = 'https://docs.netmaker.io/docs/remote-access-client-rac';
const WIREGUARD_LINK = 'https://docs.netmaker.io/docs/how-to-guides/integrating-non-native-devices';
const PRO_UPGRADE_DOCS_LINK = 'https://docs.netmaker.io/docs/server-installation/netmaker-professional-setup';
const RAC_DOWNLOAD_DOCS_LINK = 'https://docs.netmaker.io/docs/remote-access-client-rac#download-installation';
const CORE_DNS_SETUP_LINK = 'https://docs.netmaker.io/docs/about/architecture#components__coredns';
const NFTABLES_DOCS_LINK = 'https://www.linode.com/docs/guides/how-to-use-nftables/';
const IPTABLES_DOCS_LINK = 'https://ioflood.com/blog/install-iptables-command-linux/';
const INTEGRATING_NON_NATIVE_DEVICES_LINK =
  'https://docs.netmaker.io/docs/how-to-guides/integrating-non-native-devices#integrating-non-native-devices--page-root';
const ROUTE_LOCAL_NETWORK_TRAFFIC_LINK = 'https://docs.netmaker.io/'; /// NEED TO ADD LINK
const HOW_TO_ADD_USERS_TO_NETWORK = 'https://www.netmaker.io/resources/how-to-add-users-to-netmaker-saas';
const USER_MGMT_DOCS_URL = 'https://docs.netmaker.io/#';
const USER_MGMT_DOCS_PAL_URL =
  'https://docs.netmaker.io/docs/operations-guide/granting-access-to-your-vpn#roles__platform-roles-server-wide-access-level';
const UI_DOCS_URL = 'https://docs.netmaker.io/docs/references/user-interface';
const USER_MGMT_DOCS_NETWORK_ROLES_URL = 'https://docs.netmaker.io/docs/features/users-management-pro#network-roles';
const USER_MGMT_DOCS_USER_GROUPS_URL = 'https://docs.netmaker.io/docs/features/users-management-pro#user-groups';
const USER_MGMT_DOCS_INVITES_URL =
  'https://docs.netmaker.io/docs/features/users-management-pro#user-groups#adding-users__user-invite';
const APPLE_STORE_LINK = 'https://apps.apple.com/us/app/netmaker-rac/id6479694220?itsct=apps_box_badge&amp;itscg=30200';
const PLAY_STORE_LINK =
  'https://play.google.com/store/apps/details?id=com.net.netmaker&pli=1&utm_source=nmui&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1';
const HOSTS_DOCS_URL = 'https://docs.netmaker.io/ui-reference.html#hosts';
const ACLS_DOCS_URL = 'https://docs.netmaker.io/acls.html';
const RELAYS_DOCS_URL = 'https://docs.netmaker.io/pro/pro-relay-server.html';
const EGRESS_DOCS_URL = 'https://docs.netmaker.io/egress-gateway.html';
const GATEWAYS_DOCS_URL = 'https://docs.netmaker.io/external-clients.html';
const CLIENTS_DOCS_URL = 'https://docs.netmaker.io/external-clients.html#adding-clients-to-a-gateway';
const INTERNET_GATEWAYS_DOCS_URL = 'https://docs.netmaker.io/pro/internet-gateways.html';

const EGRESS_IMG = '/egress.webp';
const RAG_IMG = '/rag.webp';
const NET_IMG = '/network.webp';
const WELCOME_IMG = '/welcome-image.webp';
const RAC_DOWNLOAD_LINK = 'https://www.netmaker.io/download';
const RAC_DOCS_URL = 'https://docs.netmaker.io/docs/remote-access-client-rac';
const TAGS_DOCS_URL = 'https://docs.netmaker.io/docs/features/tag-management';

export const ExternalLinks = {
  RAC_LINK,
  WIREGUARD_LINK,
  PRO_UPGRADE_DOCS_LINK,
  RAC_DOWNLOAD_DOCS_LINK,
  CORE_DNS_SETUP_LINK,
  NFTABLES_DOCS_LINK,
  IPTABLES_DOCS_LINK,
  INTEGRATING_NON_NATIVE_DEVICES_LINK,
  ROUTE_LOCAL_NETWORK_TRAFFIC_LINK,
  HOW_TO_ADD_USERS_TO_NETWORK,
  USER_MGMT_DOCS_URL,
  UI_DOCS_URL,
  USER_MGMT_DOCS_NETWORK_ROLES_URL,
  USER_MGMT_DOCS_USER_GROUPS_URL,
  USER_MGMT_DOCS_INVITES_URL,
  RAC_DOWNLOAD_LINK,
  USER_MGMT_DOCS_PAL_URL,
  APPLE_STORE_LINK,
  PLAY_STORE_LINK,
  RAC_DOCS_URL,
  TAGS_DOCS_URL,
  HOSTS_DOCS_URL,
  ACLS_DOCS_URL,
  RELAYS_DOCS_URL,
  EGRESS_DOCS_URL,
  GATEWAYS_DOCS_URL,
  CLIENTS_DOCS_URL,
  INTERNET_GATEWAYS_DOCS_URL,
};

export const AppImages = {
  EGRESS_IMG,
  RAG_IMG,
  NET_IMG,
  WELCOME_IMG,
};
